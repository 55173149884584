import React from "react";
import {getWord} from "../../tools/tools";

export default function DealItemRound({item, translate}) {
    let circle;

    if (item.OverdueDays == 0 || (item.ObligationTypeId == 4 && item.OverdueDays >= 0) || item.ObligationTypeId == 7) {

        circle = <CircleExclamation/>;

    } else if (item.OverdueDays > 0) {

        circle = <CircleOverdue OverdueDays={item.OverdueDays} critical={item.ObligationTypeSeverityStyle === 'critical'} translate={translate} />;

    } else {

        circle = <CircleMore DaysMore={Math.abs(item.OverdueDays)} DaysLimit={item.DaysLimit} style={item.ObligationTypeSeverityStyle} full={item.ObligationTypeId == 6 && Math.abs(item.OverdueDays) > 30} translate={translate}/>

    }

    return <div className="notice-list__round">
        {circle}
        <div className="u-svg-declare">
            <svg width="0" height="0">
                <defs>
                    <linearGradient id="gradient_green">
                        <stop offset="0%" style={{stopColor: "#28710b"}}/>
                        <stop offset="100%" style={{stopColor: "#aab414"}}/>
                    </linearGradient>
                    <linearGradient id="gradient_yellow">
                        <stop offset="0%" style={{stopColor: "#ffa200"}}/>
                        <stop offset="100%" style={{stopColor: "#fbd254"}}/>
                    </linearGradient>
                    <linearGradient id="gradient_red">
                        <stop offset="0%" style={{stopColor: "#fa2719"}}/>
                        <stop offset="100%" style={{stopColor: "#ff3c06"}}/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    </div>
}

function CircleExclamation() {
    return <div className="circle-note circle-note_float-header circle-note_adapt-default">
        <div className="circle-note__round circle-note__round_light-red circle-note_screamer">
            <div className="circle-note__round-text circle-note__round-text_red">!</div>
        </div>
    </div>
}

function CircleOverdue({OverdueDays, critical, translate}) {
    let days = getWord(OverdueDays, translate('День'), translate('Дня'), translate('Дней'));

    return <div className="circle-note circle-note_float-header circle-note_adapt-default">
        <div className="circle-note__header">{translate('Просрочено')}</div>
        <div className={"circle-note__round "+(critical ? "circle-note__round_red" : "circle-note__round_light-red")}>
            <div className={"circle-note__round-text circle-note__round-text_"+(critical ? "white" : "red")}>{OverdueDays}</div>
        </div>
        <div className="circle-note__footer">{days}</div>
        <div className="circle-note__mobile-text">{days}<br/>{translate('просрочено')}</div>
    </div>;
}

export function CircleMore({DaysMore, DaysLimit, style, full, translate, leftWord}) {
    var gradient = style == 'reminder' ? 'green' : (style == 'warning' ? 'yellow' : 'red');

    let days = getWord(DaysMore, translate('День'), translate('Дня'), translate('Дней'));
    let left = leftWord ? leftWord : getWord(DaysMore, translate('остался'), translate('осталось'), translate('осталось'));

    return <div className="circle-note circle-note_float-header circle-note_adapt-default">
        <div className="circle-note__header">{left}</div>
        <div className={"circle-note__round circle-note__round_svg-circle_"+(style == "alert" ? "bright-red" : gradient)}>
            <div className="circle-note__percent-round">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
                    <circle className="back-circle" r="26" cy="29" cx="29" stroke={style == 'danger' ? "#fba2a2" : "#C8C8C8"}
                            strokeWidth="2" fill={style == 'danger' ? "#ffd1d1" : "none"}/>
                    <circle className="front-circle" r="26" cy="29" cx="29" stroke={"url(#gradient_"+gradient+")"}
                            fill="none" strokeWidth="4" strokeLinecap="round" strokeDashoffset={full ? 0 : ((DaysMore - DaysLimit)/DaysLimit)*170}
                            strokeDasharray="170 170"
                            style={{transform: "rotate(-90deg)", transformOrigin: "center"}}/>
                </svg>
            </div>
            <div className={"circle-note__round-text circle-note__round-text_svg-item"+(style != 'danger' ? "" : " circle-note__round-text_red")}>{DaysMore}</div>
        </div>
        <div className="circle-note__footer">{days}</div>
        <div className="circle-note__mobile-text">{days}<br/>{left}</div>
    </div>
}
import React, {Component} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

export default class DateRange extends Component{

    constructor(props){
        super(props);
        this.state = {
            from: props.value && props.value.from ? moment(props.value.from.split('.').reverse().join('-')) : null,
            to: props.value && props.value.to ? moment(props.value.to.split('.').reverse().join('-')) : null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };
    
    componentWillReceiveProps(nextProps){
        if (nextProps.value != this.props.value && !nextProps.value) {
            this.setState({
                from: null,
                to: null
            })
        }
    }

    componentDidMount(){
        this.dateFrom.getElementsByTagName('input')[0].readOnly = true;
        this.dateTo.getElementsByTagName('input')[0].readOnly = true;
    }

    onDateFrom = (date) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        this.setState({from: date}, () => {
            onChange && onChange(attribute, {
                from: date ? date.format('DD.MM.YYYY') : null,
                to: value.to
            })
        })

    };

    onDateTo = (date) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        this.setState({to: date}, () => {
            onChange && onChange(attribute, {
                from: value.from,
                to: date ? date.format('DD.MM.YYYY') : null
            })
        })
    };

    onDateRange = (range) => {
        return () => {
            let {
                attribute,
                onChange
            } = this.props;

            var newFrom = moment().subtract(1, range),
                newTo = moment();
            this.setState({from: newFrom, to: newTo}, () => {
                onChange && onChange(attribute, {
                    from: newFrom.format('DD.MM.YYYY'),
                    to: newTo.format('DD.MM.YYYY')
                })
            })

        }
    };

    render(){

        return <div className="daterange">
            <div className="daterange__row daterange__row_flex">
                <div className={"daterange__input"+(this.state.from ? " filled" : "")}
                     ref={(datepicker) => this.dateFrom = datepicker}
                     key="date-from"
                >
                    <DatePicker
                        selected={this.state.from}
                        onChange={this.onDateFrom}
                        maxDate={this.state.to || moment()}
                        locale={this.context.currentLanguage.alias}
                        isClearable={true}
                    />
                </div>
                <div className="daterange__separate"/>
                <div className={"daterange__input"+(this.state.to ? " filled" : "")}
                     ref={(datepicker) => this.dateTo = datepicker}
                     key="date-to"
                >
                    <DatePicker
                        selected={this.state.to}
                        onChange={this.onDateTo}
                        minDate={this.state.from}
                        maxDate={moment()}
                        locale={this.context.currentLanguage.alias}
                        isClearable={true}
                    />
                </div>
            </div>

            {this.props.showPeriods ? <div className="daterange__row">
                <span className="daterange__period" onClick={this.onDateRange('week')}>{this.context.translate("За неделю")}</span>
                <span className="daterange__period" onClick={this.onDateRange('months')}>{this.context.translate("За месяц")}</span>
                <span className="daterange__period" onClick={this.onDateRange('years')}>{this.context.translate("За год")}</span>
            </div> : null}
        </div>
    }
}
import React, {Component} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

export default class Date extends Component{

    constructor(props){
        super(props);
        this.state = {value: props.value ? moment(props.value.split('.').reverse().join('-')) : null};
    }
    
    componentWillReceiveProps(nextProps){
        if (nextProps.value != this.props.value && !nextProps.value) {
            this.setState({value: nextProps.value ? moment(nextProps.value.split('.').reverse().join('-')) : null})
        }
    }

    componentDidMount(){
        this.date.getElementsByTagName('input')[0].readOnly = true;
    }

    onDate = (date) => {
        let {
            attribute,
            value,
            onChange,
        } = this.props;

        this.setState({value: date}, () => {
            onChange && onChange(attribute, date ? date.format('DD.MM.YYYY') : null);
        })
    };

    onBlur = (e) => {
        let {
            attribute,
            onBlur,
            saveField,
            value,
        } = this.props;

        if (e && onBlur) {
            onBlur(attribute, e.target.value);
        }

        saveField && saveField(attribute, value);
    }

    render(){
        return <div className={"daterange__input"+(this.state.value ? " filled" : "")}
                     ref={(datepicker) => this.date = datepicker}>
            <DatePicker
                selected={this.state.value}
                onChange={this.onDate}
                onBlur={this.onBlur}
                maxDate={moment()}
                locale="ru-RU"
                isClearable={true}
                placeholderText={this.props.placeholder}
                
                // выводит селекты год и месяц
                peekNextMonth={this.props.yearSelection}
                showMonthDropdown={this.props.yearSelection}
                showYearDropdown={this.props.yearSelection}
                dropdownMode="select"
            />
        </div>
    }
}
import React, { Component } from "react";
import { history } from "../../containers/App";
import { Link } from "react-router-dom";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import OrdersDocs from "./OrdersDocs";
import DocumentLoader from "../common/DocumentLoader";
import PropTypes from "prop-types";
import Modal from "../common/Modal";
import DocumentsAdded from "../companies/DocumentsAdded";

import {priceMask, phoneMask, onlyNumbers, snilsMask, divisionCodeMask, passportMask} from "../../tools/mask";
import {getFormValues} from "../../tools/form-helper";
import {getQueryParam} from "../../tools/tools";

export default class OrdersForm extends Component {
    constructor(props) {
        super(props);

        var days = [];
        for (var i = 1; i < 29; i++) {
            days.push({
                value: i,
                label: i
            })
        }

        // для селекта "Количество предметов лизинга"
        var quantity = [];
        for (var i = 1; i <= 50; i++) {
            quantity.push({
                value: i,
                label: i
            })
        }

        // значения по умолчанию для элементов формы, источник в редьюсере
        var order = props.fields || {};

        // console.log('1 - данные из апи для элементов формы', props.catalog);
        // console.log('2 - сохраненные поля формы', order);

        this.sendingCost = false;
        this.state = {
            // ПРЕДМЕТ ЛИЗИГА
            EquipmentTypeId: {
                type: 'select',
                label: "Тип предмета лизинга",
                placeholder: "Выберите тип предмета лизинга",
                value: order.EquipmentTypeId || "",
                error: '',
                options: props.catalog && props.catalog.equipmentTypes || [],
                optionsType: 'equipmentTypes',
                success: false,
                allowEmpty: false,
                // required: true,
            },
            EquipmentBrandName: {
                type: 'auto-complete',
                label: "Производитель (бренд)",
                placeholder: "Начните вводить название",
                value: order.EquipmentBrandName || "",
                error: '',
                options: props.catalog && props.catalog.equipmentBrands || [],
                optionsType: 'equipmentBrands',
                // getOptions: props.getCatalog,
                success: !!order.EquipmentBrandName,
                canBlockBlur: true
            },
            EquipmentName: {
                type: 'text',
                label: "Наименование предмета лизинга",
                value: order.EquipmentName || '',
                error: '',
                success: !!order.EquipmentName,
                placeholder: '',
                required: true,
            },
            EquipmentCost: {
                type: 'number',
                label: "Цена",
                value: order.EquipmentCost && priceMask(order.EquipmentCost) || "",
                error: '',
                mask: priceMask,
                success: !!order.EquipmentCost,
                required: true,
            },
            EquipmentCostCurrencyId: {
                type: 'select',
                value: order.EquipmentCostCurrencyId || 2,
                error: '',
                options: props.catalog && props.catalog.currencies || [],
                optionsType: 'currencies',
                // required: true,
                success: !!order.currencies
            },
            EquipmentQuantity: {
                type: 'select',
                label: "Количество предметов лизинга",
                value: order.EquipmentQuantity || 1,
                error: '',
                options: quantity,
                success: !!order.EquipmentQuantity
            },
            IsEquipmentUsed: {
                type: 'checkbox',
                label: 'Бывшее в употреблении',
                value: +order.IsEquipmentUsed === 1 ? true : false || false,
                id: 'IsEquipmentUsed'
            },
            EquipmentOperationRegionName: {
                type: 'auto-complete',
                label: "Регион эксплуатации",
                placeholder: "Выберите из списка",
                value: order.EquipmentOperationRegionName || "",
                error: '',
                options: props.catalog && props.catalog.regions || [],
                optionsType: 'regions',
                // getOptions: props.getCatalog,
                success: !!order.EquipmentOperationRegionName,
                canBlockBlur: true
            },
            EquipmentStorageAreaAddress: {
                type: 'text',
                label: "Адрес хранения предмета лизинга",
                value: order.EquipmentStorageAreaAddress || "",
                error: '',
                success: !!order.EquipmentStorageAreaAddress,
                placeholder: 'Адрес нахождения предмета лизинга в нерабочее время',
            },

            // ИНФОМАЦИЯ О ПОСТАВЩИКЕ
            SupplierId: {
                // type: 'select',
                type: 'filter-auto-complete',
                label: "Укажите компанию",
                placeholder: "Начните вводить наименование",
                value: order.SupplierId || "",
                error: '',
                options: props.catalog && props.catalog.suppliers || [],
                optionsType: 'suppliers',
                // getOptions: props.getCatalog,
                success: !!order.SupplierId,
                allowEmpty: true,
            },
            SupplierContactPersonWebsite: {
                type: 'text',
                label: "Сайт",
                name: "site",
                value: order.SupplierContactPersonWebsite || "",
                // required: true,
                error: ''
            },

            // УСЛОВИЯ ФИНАНСИРОВАНИЯ
            AdvancePercent: {
                type: 'number-slide',
                label: "Аванс",
                value: order.AdvancePercent && order.AdvancePercent >= 0 ? order.AdvancePercent : 0,
                mask: onlyNumbers,
                error: '',
                min: 0,
                max: 49,
                step: 1,
                postfix: '%',
                // required: true,
                success: order.AdvancePercent || order.AdvancePercent === 0
            },
            ContractTerm: {
                type: 'number-slide',
                label: "Срок",
                value: order.ContractTerm || 6,
                mask: onlyNumbers,
                error: '',
                min: 6,
                max: 120,
                step: 6,
                postfix: 'мес',
                // required: true,
                success: !!order.ContractTerm
            },
            LeasePaymentDay: {
                type: 'select',
                label: "Предпочтительная дата платежа",
                value: order.LeasePaymentDay || 22,
                error: '',
                options: days,
                success: !!order.LeasePaymentDay
            },

            // КОМПАНИЯ-ЛИЗИНГОПОЛУЧАТЕЛЬ
            LesseeId: {
                type: 'select',
                label: "Укажите компанию",
                placeholder: "Выберите из списка",
                value: order.LesseeId || "",
                error: '',
                options: props.catalog && props.catalog.leaseCompanies || [],
                optionsType: 'leaseCompanies',
                success: !!order.LesseeId,
                allowEmpty: true,
            },
            LesseeReorganizedFullName: {
                type: 'text',
                label: "Полное наименование",
                value: order.LesseeReorganizedFullName || "",
                error: '',
                success: !!order.LesseeReorganizedFullName,
                placeholder: 'Полное наименование',
                // disabled: true,
            },
            LesseeReorganizedShortName: {
                type: 'text',
                label: "Краткое наименование",
                value: order.LesseeReorganizedShortName || "",
                error: '',
                success: !!order.LesseeReorganizedShortName,
                placeholder: 'Краткое наименование',
                // disabled: true,
            },
            LesseeReorganizedOGRN: {
                type: 'text',
                label: "ОГРН",
                value: order.LesseeReorganizedOGRN || "",
                error: '',
                // success: !!order.EquipmentStorageAreaAddress,
                placeholder: '',
                // disabled: true,
            },
            IsLesseeReorganized: {
                type: 'checkbox',
                label: 'Юридическое лицо создано путем реорганизации или продолжает деятельность после реорганизации',
                value: +order.IsLesseeReorganized === 1 ? true : false || false,
                id: 'IsLesseeReorganized'
            },
            LesseeLeader: {
                type: 'text',
                label: "Руководитель",
                value: order.LesseeLeader || "",
                error: '',
                success: !!order.LesseeLeader,
                placeholder: 'Введите ФИО',
            },

            LesseeEmail: {
                type: 'text',
                label: "E-mail",
                name: "email",
                value: order.LesseeEmail || "",
                // required: true,
                error: '',
                placeholder: 'mailexample@mail.ru',
            },
            LesseePhone: {
                type: 'phone_number',
                label: "Телефон",
                name: "authPhone",
                value: order.LesseePhone || "",
                // required: true,
                error: '',
                mask: phoneMask,
                ref: (input) => {
                    this.phoneInput = input;
                },
                placeholder: '+7 ( ___ ) ___ - __ - __ ',
            },
            LesseeRegDate: {
                type: 'date',
                label: "Дата регистрации",
                value: order.LesseeRegDate || "",
                placeholder: '01.01.1990',
                yearSelection: true,
            },
            LesseeWebsite: {
                type: 'text',
                label: "Сайт",
                name: "site",
                value: order.LesseeWebsite || "",
                // required: true,
                error: '',
                placeholder: 'sitedomain.ru',
            },
            LesseeBirthdate: {
                type: 'date',
                label: "Дата рождения",
                value: order.LesseeBirthdate || "",
                placeholder: '01.01.1990',
                yearSelection: true,
            },
            LesseeSnils: {
                type: 'text',
                label: "СНИЛС",
                value: order.LesseeSnils && snilsMask(order.LesseeSnils) || "",
                error: '',
                // required: true,
                mask: snilsMask,
                success: !!order.LesseeSnils,
                placeholder: '123-123-123-12',
            },
            LesseeBirthplace: {
                type: 'text',
                label: "Место рождения",
                value: order.LesseeBirthplace || "",
                error: '',
                // required: true,
                success: !!order.LesseeBirthplace,
                placeholder: 'г. Москва',
            },
            LesseePassportNumber: {
                type: 'text',
                label: "Серия и номер паспорта",
                value: order.LesseePassportNumber && passportMask(order.LesseePassportNumber) || "",
                error: '',
                // required: true,
                mask: passportMask,
                success: !!order.LesseePassportNumber,
                placeholder: '12 34 567890',
            },
            LesseePassportDate: {
                type: 'date',
                label: "Дата выдачи",
                value: order.LesseePassportDate || "",
                placeholder: '01.01.1990',
                yearSelection: true,
            },
            LesseePassportDivisionCode: {
                type: 'text',
                label: "Код подразделения",
                value: order.LesseePassportDivisionCode && divisionCodeMask(order.LesseePassportDivisionCode) || "",
                error: '',
                // required: true,
                mask: divisionCodeMask,
                success: !!order.LesseePassportDivisionCode,
                placeholder: '123-123',
            },
            LesseePassportIssue: {
                type: 'text',
                label: "Кем выдан",
                value: order.LesseePassportIssue || "",
                error: '',
                // required: true,
                success: !!order.LesseePassportIssue,
            },

            IsMailing: {
                type: 'checkbox',
                label: 'Даю согласие на получение автоматических рассылок.',
                value: +order.IsMailing === 1 ? true : false || false,
                id: 'IsMailing'
            },

            // КОММЕНТАРИИ К ЗАЯВКЕ
            Comment: {
                type: 'textarea',
                label: "",
                placeholder: "Введите комментарий",
                value: order.Comment || "",
                error: '',
                success: !!order.Comment
            },

            // чекбоксы над кнопкой ОТППРАВИТЬ ЗАЯВКУ
            IsIagree: {
                type: 'checkbox',
                label: 'Вся представленная в Заявке (включая Приложения) информация является достоверной и полной, никакая информация не утаена и не искажена. В соответствии с действующим законодательством сделка (взаимосвязанные сделки), на заключение которой(ых) подается настоящая Заявка не требует(ют) согласия на ее(их) совершение. ',
                value: false,
                id: 'IsIagree'
            },
            IsIagree2: {
                type: 'checkbox',
                label: 'Даю согласие на получение информации о Заявителе (кредитных отчетов) в бюро кредитных историй.',
                value: false,
                id: 'IsIagree2'
            },
            IsIagree3: {
                type: 'checkbox',
                label: 'Даю согласие на оценку кредитоспособности (скоринговой оценки) в АО «Объединенное Кредитное Бюро».',
                value: false,
                id: 'IsIagree3'
            },
            IsIagree4: {
                type: 'checkbox',
                label: 'Даю согласие на обработку персональных данных, полученных Заявителем (являющимся оператором персональных данных в терминологии Федерального закона «О защите персональных данных» от 27.07.2006 №152-ФЗ).',
                value: false,
                id: 'IsIagree4'
            },


            // для выпадашки в блоке Информация о поставщике
            SupplierTin: {
                value: order.SupplierTin || ""
            },
            SupplierName: {
                value: order.SupplierName || ""
            },
            SupplierKpp: {
                value: order.SupplierKpp || ""
            },


            // для выпадашки в блоке Компания-лизингополучатель
            LesseeManualFullName: {
                value: order.LesseeManualFullName || ""
            },
            LesseeManualTin: {
                value: order.LesseeManualTin || ""
            },
            LesseeManualKpp: {
                value: order.LesseeManualKpp || ""
            },

            LesseeManualShortName: {
                value: order.LesseeManualShortName || ""
            },
            LesseeOwnershipId: {
                value: order.LesseeOwnershipId || ""
            },
            LesseeManualOGRN: {
                value: order.LesseeManualOGRN || ""
            },

            LesseeManualIndexLegal: {
                value: order.LesseeManualIndexLegal || ""
            },
            LesseeManualCityLegal: {
                value: order.LesseeManualCityLegal || ""
            },
            LesseeManualAddressLegal: {
                value: order.LesseeManualAddressLegal || ""
            },

            LesseeManualIndexActual: {
                value: order.LesseeManualIndexActual || ""
            },
            LesseeManualCityActual: {
                value: order.LesseeManualCityActual || ""
            },
            LesseeManualAddressActual: {
                value: order.LesseeManualAddressActual || ""
            },

            LesseeManualIndexPost: {
                value: order.LesseeManualIndexPost || ""
            },
            LesseeManualCityPost: {
                value: order.LesseeManualCityPost || ""
            },
            LesseeManualAddressPost: {
                value: order.LesseeManualAddressPost || ""
            },


            isChanged: {
                value: false
            }
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount() {
        let {
            saveField,
            comeFrom,
        } = this.props;

        if (comeFrom) {
            // application added from promotion
            if (comeFrom.from == 'promotion') {
                saveField(null, 'PromotionId', comeFrom.id);
            } else if (comeFrom.from == 'potential') {
                saveField(null, 'InvestPotential', 1);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            fields,
            fileSuccess,
            selectedTypeVendorInformation,
            selectedTypeLeasingCompany,
        } = this.props;

        // в блоке "Информация о поставщике" когда добавляем новую компанию, то очищаем поле "Укажите компанию" и меняем placeholder
        if ((prevProps.selectedTypeVendorInformation !== selectedTypeVendorInformation) && (selectedTypeVendorInformation === 'modal')) {
            fields.SupplierId = null;

            this.setState({
                ...this.state,
                SupplierId: {
                    ...this.state.SupplierId,
                    placeholder: 'Другое',
                    value: '',
                },
            });
        }
        // в блоке "Компания-лизингополучатель" когда добавляем новую компанию, то очищаем поле "Укажите компанию" и меняем placeholder
        if ((prevProps.selectedTypeLeasingCompany !== selectedTypeLeasingCompany) && (selectedTypeLeasingCompany === 'modal')) {
            fields.LesseeId = null;
            fields.IsLesseeReorganized = false;
            fields.LesseeReorganizedFullName = '';
            fields.LesseeReorganizedOGRN = '';
            fields.LesseeReorganizedShortName = '';

            this.setState({
                ...this.state,
                LesseeId: {
                    ...this.state.LesseeId,
                    placeholder: 'Другое',
                    value: '',
                },
                IsLesseeReorganized: {
                    ...this.state.IsLesseeReorganized,
                    value: false,
                },
                LesseeReorganizedFullName: {
                    ...this.state.LesseeReorganizedFullName,
                    value: '',
                },
                LesseeReorganizedOGRN: {
                    ...this.state.LesseeReorganizedOGRN,
                    value: '',
                },
                LesseeReorganizedShortName: {
                    ...this.state.LesseeReorganizedShortName,
                    value: '',
                },
            });
        }

        if (prevProps.fileSuccess !== fileSuccess && fileSuccess) {
            // открывает модалку после загрузки документов "Документы добавлены"
            this.props.openModalAddedDoc();
        }
    }

    // загружает документ
    addDocument = (file) => {
        this.props.addApplicationDocument(this.props.fields && this.props.fields.ApplicationId || null, file);
    }

    removeDocument = (id) => {
        this.props.removeApplicationDocument(this.props.fields.ApplicationId, id);
    }

    updateDocument = (id, name) => {
        this.props.updateApplicationDocument(this.props.fields.ApplicationId, id, name)
    }

    addCatalogOptions = (state, catalog) => {
        for (var key in state) {
            if (state[key].hasOwnProperty('optionsType') && state[key].options != catalog[state[key].optionsType]) {
                state[key].options = catalog[state[key].optionsType];
            }
        }
        return state;
    }

    addValues = (state, application) => {
        for (var key in state) {
            if (key === 'isChanged') {
                state.isChanged.value = true;
                continue;
            }
            if (application && application.hasOwnProperty(key)) {
                if (this.props.fields && this.props.fields[key] != application[key]) {
                    var val = application[key];
                    if (val && state[key].mask) val = state[key].mask(val);
                    state[key].value = val !== null ? val : "";
                }
            } else {
                state[key].value = "";
            }
        }

        return state;
    }

    componentWillReceiveProps(nextProps) {
        var state = {...this.state};
        if (nextProps.catalog != this.props.catalog) {
            state = this.addCatalogOptions(state, nextProps.catalog);
        }
        if (nextProps.success != this.props.success) {
            for (var key in state) {
                if (nextProps.success && nextProps.success[key] == 'ok') state[key].success = true;
                else state[key].success = false;
            }
        }
        if (nextProps.fields != this.props.fields) {
            state = this.addValues(state, nextProps.fields);
        }
        if (JSON.parse(JSON.stringify(nextProps.errors || {})) != JSON.parse(JSON.stringify(this.props.errors || {}))) {
            for (var key in state) {
                if (key === 'EquipmentCost') {
                    if (this.sendingCost || state[key].error === 'Это поле необходимо заполнить') state[key].error = nextProps.errors ? nextProps.errors[key] : ""
                } else {
                    state[key].error = nextProps.errors ? nextProps.errors[key] : ""
                }
            }
        }

        if (
            ((nextProps.status != this.props.status) && nextProps.status == 'send-error')
            // || window.location.href.includes('/update/')
        ) {
            console.log('Сработала валидация на бэке', this.props.status);
            // скроллит к элементу формы (если на бэке установлена валидация) у которого ошибка
            for (var key in state) {
                if (key === 'EquipmentCost') {
                    if (this.sendingCost) state[key].error = nextProps.errors ? nextProps.errors[key] : ""
                } else {
                    state[key].error = nextProps.errors ? nextProps.errors[key] : ""
                }
                if (state[key].error) {
                    // если какой-то элемент формы скрыт, а к нему будет осуществляться скролл якобы у него есть ошибка, то крашился проект
                    const notFound = document.getElementsByClassName(key).length < 1;

                    if (!notFound) {
                        var to = document.getElementsByClassName(key)[0].closest('.form__row').offsetTop;
                        window.scrollTo(0, to);
                        break;
                    }
                }
            }
        }
        if (state != this.state) this.setState(state);
    }

    // для манипуляции с элементами формы ("Регион эксплуатации", "Адрес хранения предмета лизинга")
    changeRegionField = (attribute, value) => {
        let state = {...this.state};
        let {
            fields,
            saveField,
        } = this.props;

        // при измененеии "Тип предмета лизинга"
        if (attribute === 'EquipmentTypeId') {
            if (+value === 1 || +value === 2 || +value === 3) {
                // меняем плейсхолдер у поля "Адрес хранения предмета лизинга"
                state.EquipmentStorageAreaAddress.placeholder = 'Адрес нахождения предмета лизинга в нерабочее время';
            } else {
                state.EquipmentStorageAreaAddress.placeholder = 'Месторасположение предмета лизинга';
                // очищаем выбранное знавение на сервере в поле "Регион эксплуатации"
                if (fields && fields.ApplicationId) this.saveField('EquipmentOperationRegionName', '');
            }
        }
    }

    // при изменении селекта "Укажите компанию" в блоке "Информация о поставщике"
    changeSupplierIdField = (attribute, value) => {
        let {
            selectedTypeVendorInformationAction,
        } = this.props;

        if (attribute === 'SupplierId') {
            selectedTypeVendorInformationAction('main');
        }
    }

    // если выбираем пустую опцию, то отправляем на бек все поля которые были заполнены в модалке НОВАЯ КОМПАНИЯ
    dublicateSendModalFormNewCompany = (attribute, value) => {
        let {
            fields,
            addCompanyModal,
        } = this.props;

        if (attribute === 'LesseeId' && value === '') {
            const fieldsModal = {
                LesseeManualFullName: fields.LesseeManualFullName ? fields.LesseeManualFullName.replace(/\"/gim, '') : '',
                LesseeManualTin: fields.LesseeManualTin || '',
                LesseeManualKpp: fields.LesseeManualKpp || '',
                LesseeManualShortName: fields.LesseeManualShortName || '',
                LesseeOwnershipId: fields.LesseeOwnershipId || '',
                LesseeManualOGRN: fields.LesseeManualOGRN || '',

                // юридический адрес
                LesseeManualIndexLegal: fields.LesseeManualIndexLegal || '',
                LesseeManualCityLegal: fields.LesseeManualCityLegal || '',
                LesseeManualAddressLegal: fields.LesseeManualAddressLegal || '',

                // фактический адрес
                LesseeManualIndexActual: fields.LesseeManualIndexActual || '',
                LesseeManualCityActual: fields.LesseeManualCityActual || '',
                LesseeManualAddressActual: fields.LesseeManualAddressActual || '',
                IsLesseeManualAddressActualLegalMatched: fields.FactAddressEqualsLegalAddress ? 1 : 0,

                // Адрес для корреспонденции
                IsLesseeManualAddressPostLegalMatched: fields.IsLesseeManualAddressPostLegalMatched ? 1 : 0,
                IsLesseeManualAddressActualPostMatched: fields.IsLesseeManualAddressActualPostMatched ? 1 : 0,
                LesseeManualIndexPost: fields.LesseeManualIndexPost || '',
                LesseeManualCityPost: fields.LesseeManualCityPost || '',
                LesseeManualAddressPost: fields.LesseeManualAddressPost || '',
            };

            addCompanyModal && addCompanyModal(fieldsModal);
        }
    }

    // при изменении селекта "Укажите компанию" в блоке "Компания-лизингополучатель" перезапрашиваем данные с бека для этого селекта
    changeLesseeIdField = (attribute, value) => {
        let {
            fields,
            applicationFormInfo,
            selectedTypeLeasingCompanyAction,
        } = this.props;

        // при измененеии "Тип предмета лизинга"
        if (attribute === 'LesseeId') {
            applicationFormInfo((fields && fields.ApplicationId) ? fields.ApplicationId : '');
            selectedTypeLeasingCompanyAction('main');
        }
    }

    // при изменении селекта "Укажите компанию" в блоке "Компания-лизингополучатель" в полях:
    // "Руководитель", "Полное наименование", "Краткое наименование", "ОГРН" и у чекбокса будут подставляться определенное значение
    fillFieldLesseeLeader = (attribute, value) => {
        let state = {...this.state};
        let {fields, saveField, catalog} = this.props;
        // при измененеии "Укажите компанию" в блоке "Компания-лизингополучатель"
        if (attribute === 'LesseeId') {
            const findleaseCompanies = catalog && catalog.leaseCompanies && catalog.leaseCompanies.find((i) => i.value === value);

            // подставляем значения в стор
            fields.IsLesseeReorganized = (findleaseCompanies && findleaseCompanies.is_lessee_reorganized) ? !!findleaseCompanies.is_lessee_reorganized : false;
            fields.LesseeLeader = (findleaseCompanies && findleaseCompanies.leader_full_name) ? findleaseCompanies.leader_full_name : '';
            fields.LesseeReorganizedFullName = (findleaseCompanies && findleaseCompanies.lessee_reorganized_fullname) ? findleaseCompanies.lessee_reorganized_fullname : '';
            fields.LesseeReorganizedOGRN = (findleaseCompanies && findleaseCompanies.lessee_reorganized_ogrn) ? findleaseCompanies.lessee_reorganized_ogrn : '';
            fields.LesseeReorganizedShortName = (findleaseCompanies && findleaseCompanies.lessee_reorganized_shortname) ? findleaseCompanies.lessee_reorganized_shortname : '';

            // подставляем значения в стейт для визуального отображения
            this.setState({
                ...this.state,
                IsLesseeReorganized: {
                    ...this.state.IsLesseeReorganized,
                    value: fields.IsLesseeReorganized,
                },
                LesseeLeader: {
                    ...this.state.LesseeLeader,
                    value: fields.LesseeLeader,
                },
                LesseeReorganizedFullName: {
                    ...this.state.LesseeReorganizedFullName,
                    value: fields.LesseeReorganizedFullName,
                },
                LesseeReorganizedOGRN: {
                    ...this.state.LesseeReorganizedOGRN,
                    value: fields.LesseeReorganizedOGRN,
                },
                LesseeReorganizedShortName: {
                    ...this.state.LesseeReorganizedShortName,
                    value: fields.LesseeReorganizedShortName,
                },
            });
        }
    }

    // срабатывает при каждом измененеи элементов формы
    onChangeField = (attribute, value, isChangedFromLesseeInnBtn) => {
        var {
            fields,
            saveField,
            catalog,
        } = this.props;

        this.sendingCost = false;
        this.props.setField(attribute, value);


        this.setState((prevState) => {
            return {
                ...prevState,
                [attribute]: {
                    ...prevState[attribute],
                    value
                },
            };
        }, () => {
            if(!this.props.fields.isDraft && attribute === 'EquipmentCost') {
                this.setState({EquipmentCost: {...this.state.EquipmentCost, error: ''}})
            }
        });
        this.setIsChanged();

        this.changeRegionField(attribute, value);
        this.changeSupplierIdField(attribute, value);



        // проверка для отслеживания изменения, которое было активировано при клике на кнопку Добавить по ИНН
        if (!isChangedFromLesseeInnBtn) {
            // 1-при изменении селекта Укажите компанию в блоке "Компания-лизингополучатель" перезапрашиваем данные с бека для этого селекта
            this.changeLesseeIdField(attribute, value);
            // 2
            setTimeout(() => {
                if (attribute === 'LesseeId') {
                    // когда изменяем селект "Укажите компанию" в блоке "Компания-лизингополучатель" отправляем на бек сразу несколько значений в виде строки
                    // в саге fetchSaveField отделяем id для LesseeId
                    const findleaseCompanies = catalog && catalog.leaseCompanies && catalog.leaseCompanies.find((i) => i.value === value);
                    const allParams = `${findleaseCompanies && (`&IsLesseeReorganized=${findleaseCompanies.is_lessee_reorganized}&LesseeReorganizedFullName=${findleaseCompanies.lessee_reorganized_fullname}&LesseeReorganizedOGRN=${findleaseCompanies.lessee_reorganized_ogrn}&LesseeReorganizedShortName=${findleaseCompanies.lessee_reorganized_shortname}&LesseeLeader=${findleaseCompanies.leader_full_name}`)}`
                    if (fields && fields.ApplicationId) saveField(fields && fields.ApplicationId, attribute, `${value}${allParams === "undefined" ? '' : allParams}`);
                }
            }, 200);
            // 3-setTimeout для того, что бы в первую очередь отработал селект LesseeId, а уже потом все остальные поля
            setTimeout(() => {
                if (fields && fields.ApplicationId) this.fillFieldLesseeLeader(attribute, value);
            }, 300);

            // 4
            setTimeout(() => {
                // если кликаем по чекбоксе "Юр лицо..."
                if (attribute === 'IsLesseeReorganized') {
                    if (!value) {
                        // очищаем поля локально в сторе
                        fields.LesseeReorganizedFullName = '';
                        fields.LesseeReorganizedOGRN = '';
                        fields.LesseeReorganizedShortName = '';

                        // очищаем поля на беке
                        saveField(fields && fields.ApplicationId, 'LesseeReorganizedFullName', '');
                        saveField(fields && fields.ApplicationId, 'LesseeReorganizedOGRN', '');
                        saveField(fields && fields.ApplicationId, 'LesseeReorganizedShortName', '');
                    }
                }
            }, 400);

            setTimeout(() => {
                this.dublicateSendModalFormNewCompany(attribute, value);
            }, 500);
        }
    };

    setIsChanged = () => {
        this.setState({isChanged: {value: true}});
    }

    // после каждого заполнения элемента формы, отправляются его данные
    saveField = (attribute, value) => {
        var {
            fields,
            saveField,
            saveFields,
            comeFrom,
            catalog
        } = this.props;

        this.sendingCost = false;
        if (fields && fields.ApplicationId) {
            //application updating - после того как создан черновик последующие изменения формы отправляется каждый раз
            
            // для определенных чекбоксов меняем тип данных с булева на чесло, это нужно что бы бэк не ругался, он принимет 1 или 0
            if (attribute === 'IsEquipmentUsed' || attribute === 'IsLesseeReorganized' || attribute === 'IsMailing') {
                saveField(fields && fields.ApplicationId, attribute, value === true ? 1 : 0);
            } else if (attribute === 'IsIagree' || attribute === 'IsIagree2' || attribute === 'IsIagree3' || attribute === 'IsIagree4') {
                // если срабатывают эти чекбоксы, то на бэк ничего не отправляем
            } else if (attribute === 'EquipmentQuantity' && this.state.EquipmentCost.value) {
                // если введена "Стоимость предмета лизинга", то отправлять вместе с "Количество предметов лизинга"
                this.sendingCost = true;
                saveField(fields && fields.ApplicationId, attribute, value + '&EquipmentCost=' + this.state.EquipmentCost.value.replace(/\s+/g, '').trim());

            } else if (attribute === 'EquipmentCost' && this.state.EquipmentQuantity.value) {
                // отправлять "Стоимость предмета лизинга" вместе с "Количество предметов лизинга"
                this.sendingCost = true;
                saveField(fields && fields.ApplicationId, attribute, value + '&EquipmentQuantity=' + this.state.EquipmentQuantity.value);
            } else {
                // для всех остальных элементов формы оставляем как и было
                saveField(fields && fields.ApplicationId, attribute, value);
            }

        } else {
            // if (comeFrom) {
                // application added from promotion
                // if (comeFrom.from == 'promotion') {
                //     var attributes = {[attribute]: value, PromotionId: comeFrom.id};
                //     saveFields(null, attributes);
                // } else if (comeFrom.from == 'potential') {
                //     var attributes = {[attribute]: value, InvestPotential: 1};
                //     saveFields(null, attributes);
                // }
            //     if (comeFrom.from == 'promotion') {
            //         saveField(null, 'PromotionId', comeFrom.id);
            //     } else if (comeFrom.from == 'potential') {
            //         saveField(null, 'InvestPotential', 1);
            //     }
            // } else {
                //create new draft - первое измененеие формы (изменение инпута или селекта) создает черновик (applicationId)

                // if(comeFrom) {
                //     if (comeFrom.from == 'promotion') {
                //         saveField(null, 'PromotionId', comeFrom.id);
                //     } else if (comeFrom.from == 'potential') {
                //         saveField(null, 'InvestPotential', 1);
                //     }
                // }
                // если вначале отправляется сохранение Компания-лизингополучателя, то сразу отправляется полная информация
                if (attribute === 'LesseeId') {
                    setTimeout(() => {
                        if (attribute === 'LesseeId') {
                            // когда изменяем селект "Укажите компанию" в блоке "Компания-лизингополучатель" отправляем на бек сразу несколько значений в виде строки
                            // в саге fetchSaveField отделяем id для LesseeId
                            const findleaseCompanies = catalog && catalog.leaseCompanies && catalog.leaseCompanies.find((i) => i.value === value);
                            const allParams = `${findleaseCompanies && (`&IsLesseeReorganized=${findleaseCompanies.is_lessee_reorganized}&LesseeReorganizedFullName=${findleaseCompanies.lessee_reorganized_fullname}&LesseeReorganizedOGRN=${findleaseCompanies.lessee_reorganized_ogrn}&LesseeReorganizedShortName=${findleaseCompanies.lessee_reorganized_shortname}&LesseeLeader=${findleaseCompanies.leader_full_name}`)}`
                            saveField(null, attribute, `${value}${allParams === "undefined" ? '' : allParams}`);
                        }
                    }, 200);
                    // 3-setTimeout для того, что бы в первую очередь отработал селект LesseeId, а уже потом все остальные поля
                    setTimeout(() => {
                        this.fillFieldLesseeLeader(attribute, value);
                    }, 300);
                } else {
                    if (attribute === 'EquipmentCost' || attribute === 'EquipmentQuantity') {
                        this.sendingCost = true;
                    }
                    saveField(null, attribute, value);
                }
            // }
        }
    };

    renderField = (attribute, className = "") => {
        var fields = {...this.state};

        return fields.hasOwnProperty(attribute) ?
            <FormField className={className + " " + attribute}
                       fieldConfig={{
                           ...fields[attribute],
                           label: this.context.translate(fields[attribute].label),
                           placeholder: this.context.translate(fields[attribute].placeholder)
                       }}
                       attribute={attribute}
                       onChange={this.onChangeField}
                       saveField={!this.props.fields || this.props.fields.IsDraft ? this.saveField : null} //send value to server
            /> : null;
    };

    onSubmit = (withSign) => {

        let {
            fields,
            sendApplication,
            saveFields,
            IsRecreate,
            deleteProviderCompanyForm,
            deleteCompanyForm,
        } = this.props;

        // если зашли от админки, то нельзя подписывать
        if(withSign && window.location.href.indexOf('frontend') !== -1) return;

        this.sendingCost = true;
        // когда элемент формы получил ошибку, скроллим к нему окрашиваем в красный цвет
        if (!fields || !fields.ApplicationId) {
            var stateFields = {...this.state};
            var to = window.pageYOffset;
            for (var attr in stateFields) {
                if (stateFields[attr].required && !stateFields[attr].value) {
                    stateFields[attr].error = this.context.translate("Обязательное поле");
                    if (to == window.pageYOffset) {
                        to = document.getElementsByClassName(attr)[0].closest('.form__row').offsetTop;
                    }
                }
            }

            this.setState({...stateFields}, () => {
                console.log('валидация на фронте');
                // (если не создан черновик (первое изменение формы)) то
                // скроллит к элементу формы у которого ошибка и если в state установлено поле required
                window.scrollTo(0, to);
            });
        } else if (fields.IsDraft) {
            // отправка формы если мы находимся на странице "Отправить заявку - /applications/create"
            sendApplication && sendApplication(fields);

            // очищаем поля в формах "Добавить по ИНН"
            deleteProviderCompanyForm();
            deleteCompanyForm();

        } else {
            // отправка формы если мы ее редактируем
            var values = getFormValues({...this.state});

            let convertValues = {
                ...values,
                IsEquipmentUsed: values.IsEquipmentUsed ? 1 : 0,
                IsLesseeReorganized: values.IsLesseeReorganized ? 1 : 0,
                IsMailing: values.IsMailing ? 1 : 0,
                // если чекбокс ЮР ЛИЦО... выключен, то эти три поля не передаем на бэк
                LesseeReorganizedFullName: (values.IsLesseeReorganized && !!values.LesseeReorganizedFullName) ? values.LesseeReorganizedFullName : '',
                LesseeReorganizedShortName: (values.IsLesseeReorganized && !!values.LesseeReorganizedShortName) ? values.LesseeReorganizedShortName : '',
                LesseeReorganizedOGRN: (values.IsLesseeReorganized && !!values.LesseeReorganizedOGRN) ? values.LesseeReorganizedOGRN : '',
            };

            saveFields && saveFields(IsRecreate ? null : fields.ApplicationId, convertValues);
        }
    };

    clearDocs = () => {
        if (this.props.tmpDocs && this.props.tmpDocs.length) {
            this.props.tmpDocs.map((doc) => {
                this.removeDocument(doc);
            })
        }
    }

    hasChanges = () => {
        var fields = this.state;
        var oldFields = this.props.fields;

        for (var attr in fields) {
            if (fields[attr].type == "number") {
                if (fields[attr].value.replace(/\D/gim, '') != oldFields[attr]) {
                    return true;
                }
            } else if (fields[attr].value != oldFields[attr] && !(oldFields[attr] == null && fields[attr].value == "")) {
                return true;
            }
        }
        return false;
    }

    // удаляем добавленную компанию
    deleteCompany = (id) => {
        let state = {...this.state};
        const {
            fields,
            saveField,
            deleteProviderCompanyForm,
            deleteCompanyForm,
        } = this.props;

        // для блока Информация о поставщике
        if (id === 'supplierInformation') {
            const arr = ['SupplierName', 'SupplierTin', 'SupplierKpp'];

            arr.forEach(i => {
                // очищаем значения из локального состояния
                if (i !== 'SupplierName') fields[i] = '';
                // очищаем значения на стороне сервера
                saveField(fields && fields.ApplicationId, i, '');
            });

            // через нормальный setState не меняется
            state.SupplierId.placeholder = 'Начните вводить наименование';

            // очищаем поля в модальном окне, если СПАРК нашел компанию
            deleteProviderCompanyForm();
        }

        // для блока Компания-лизингополучатель
        if (id === 'lesseeCompany') {

            const arr = [
                'LesseeManualFullName',
                'LesseeManualTin',
                'LesseeManualKpp',
                'LesseeManualShortName',
                'LesseeOwnershipId',
                'LesseeManualOGRN',
                'LesseeManualIndexLegal',
                'LesseeManualCityLegal',
                'LesseeManualAddressLegal',
                'LesseeManualIndexActual',
                'LesseeManualCityActual',
                'LesseeManualAddressActual',
                'LesseeManualIndexPost',
                'LesseeManualCityPost',
                'LesseeManualAddressPost',
            ];

            arr.forEach((i, index) => {
                // очищаем значения из локального состояния
                if (i !== 'LesseeManualFullName') fields[i] = '';
                // очищаем значения на стороне сервера
                setTimeout(() => {
                    saveField(fields && fields.ApplicationId, i, '');
                }, 100 * index);


                console.log('i', i);
            });

            // очищаем поле РУКОВОДИТЕЛЬ
            saveField(fields && fields.ApplicationId, 'LesseeLeader', '');

            // через нормальный setState не меняется
            state.LesseeId.placeholder = 'Выберите из списка';

            // очищаем поля в модальном окне, если СПАРК нашел компанию
            deleteCompanyForm();
        }
    }

    // поле с контентом конечной суммы
    contentTotalPrice = () => {

        const valuteId = +this.state.EquipmentCostCurrencyId.value;
        const priceInput = this.state.EquipmentCost.value.replace(/\s+/g, '').trim(); // убираем проблелы
        const quantity = this.state.EquipmentQuantity.value;
        let price = (+priceInput * +quantity) || 0;
        let valute = (this.props.catalog && this.props.catalog.currencies && this.props.catalog.currencies.find(el => +el.value === valuteId)) ? this.props.catalog.currencies.find(el => +el.value === valuteId).label : '';

        return (
            <div className="total_price__wrap_text">
                <span>Стоимость</span>
                <div className="total_price__text">
                    {priceMask(price)} {valute}
                </div>
            </div>
        );
    }


    render() {
        let {
            fields,
            fileBuffer,
            fileParams,
            clearBuffer,
            cancelApplicationDocument,
            IsRecreate,
            downloadApplicationFile,
            documentTypes,
            toggleAgreementModal,

            catalog,
            modalAddedDocOpened,
            closeModalAddedDoc,
            application,
        } = this.props;

        let {
            EquipmentName,
            LesseeId,
            LesseeManualFullName,
            LesseeManualTin,
            LesseeManualKpp,
            LesseeManualShortName,
            LesseeOwnershipId,
            LesseeManualOGRN,
            LesseeManualIndexLegal,
            LesseeManualCityLegal,
            LesseeManualAddressLegal,
            LesseeManualIndexActual,
            LesseeManualCityActual,
            LesseeManualAddressActual,
            LesseeManualIndexPost,
            LesseeManualCityPost,
            LesseeManualAddressPost,

            SupplierId,
            SupplierName,
            SupplierTin,
            SupplierKpp,
        } = this.state;

        const arrItems = [
            {id: 1, title: 'Баланс'},
            {id: 2, title: 'Отчет о финансовых результатах'},
            {id: 3, title: 'Анализ 51 счета'},
            {id: 4, title: 'Налоговая декларация'},
            {id: 5, title: 'Паспорта руководителя/учредителей'},
            {id: 6, title: 'Устав'},
            {id: 7, title: 'Коммерческое предложение от поставщика'},
        ];

        // блокируем кнопку
        // убираем чекбоксы в новой итерации заявок
        // const isBlockBtn = this.state.IsIagree.value && this.state.IsIagree2.value && this.state.IsIagree3.value && this.state.IsIagree4.value;

        // ищем выбранную опцию ОПФ
        const findLabelOPF = LesseeOwnershipId.value ? (catalog && catalog.lesseeOwnerships && catalog.lesseeOwnerships.find((i) => +i.value === +LesseeOwnershipId.value)) : '';
        // отображаем элемент формы "Регион эксплуатации"
        const showRegionField = (fields && fields.EquipmentTypeId) && (+fields.EquipmentTypeId === 1 || +fields.EquipmentTypeId === 2 || +fields.EquipmentTypeId === 3);
        // проверяем наличие списка компаний
        const checkLengthCompany = catalog && catalog.leaseCompanies && catalog.leaseCompanies.length;
        // отвечает за скрытие полей
        const hideFeilds = fields && +fields.IsLesseeReorganized === 1 ? true : false;


        // в блоке "Компания-лизингополучатель" ищем в списке компанию которую выбрали
        const findChosenCompany = catalog && catalog.leaseCompanies && catalog.leaseCompanies.find((i) => i.value === (fields && fields.LesseeId));
        const findOwnershipId = findChosenCompany && findChosenCompany.ownership_id;

        // показывает доп поля если выбран "индивидуальный предприниматель"
        const isIP = (fields && fields.LesseeId) ? (+findOwnershipId === 4 || +findOwnershipId === 8 || +findOwnershipId === 9) : (fields && (+fields.LesseeOwnershipId === 4 || +fields.LesseeOwnershipId === 8 || +fields.LesseeOwnershipId === 9));

        // console.log('ddd', fields);

        return <Form
            // onSubmit={this.onSubmit}
            validate={true}
        >
            <h2 className="form__title">{this.context.translate('Предмет лизинга')}</h2>
            {/*<div className="form__row">*/}
            {/*    {this.renderField('EquipmentTypeId')}*/}
            {/*    {this.renderField('EquipmentBrandName')}*/}
            {/*</div>*/}
            <div className="form__row">
                {this.renderField('EquipmentName')}
                <div className="form__field application_EquipmentCost_container">
                    <div className="form__flex">
                        {this.renderField('EquipmentCost')}
                        {this.renderField('EquipmentCostCurrencyId', "select-xs")}
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__field">
                    {this.renderField('EquipmentQuantity')}
                </div>
                <div className="form__field">
                    {this.contentTotalPrice()}
                </div>
            </div>
            <div className="form__row IsEquipmentUsedContainer">
                <div className="form__field">
                    {this.renderField('IsEquipmentUsed')}
                </div>
                <div className="form__field jcfe">
                    <a
                      className={"btn btn_clear_blue btn_add form__button btn_width"}
                      // href="#"
                      onClick={this.props.openModalKp}
                    >
                        {this.context.translate('кп от поставщика')}
                    </a>
                </div>
            </div>
            {/*{showRegionField*/}
            {/*    && (*/}
            {/*        <div className="form__row 1">*/}
            {/*            {this.renderField('EquipmentOperationRegionName')}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*<div className="form__row">*/}
            {/*    {this.renderField('EquipmentStorageAreaAddress')}*/}
            {/*</div>*/}

            <h2 className="form__title">{this.context.translate('Информация о поставщике')}</h2>
            <div className="form__row">
                {this.renderField('SupplierId')}
                <div className="form__field jcfe">
                    {(!(SupplierName.value && (!fields.SupplierId && !SupplierId.value)))
                        && (
                            <a
                                className={"btn btn_clear_blue btn_add form__button btn_width"}
                                href="#"
                                onClick={(e) => this.props.addCompany(e, 'supplierInformation')}
                            >
                                {this.context.translate('Добавить по ИНН')}
                            </a>
                        )}
                </div>
            </div>

            {SupplierName.value && (!fields.SupplierId && !SupplierId.value)
                && (
                    <div className="application-edit__company-reqs">
                        <div className="company-reqs">
                            <div className="company-reqs__wrap">
                                <div className="company-reqs__title">{SupplierName.value}</div>
                                <div className="company-reqs__inner">
                                    <div className="company-reqs__item company-reqs__item_inline">
                                        <strong>{this.context.translate('ИНН')}:&nbsp;</strong>
                                        {SupplierTin.value}
                                    </div>
                                    <div className="company-reqs__item">
                                        <strong>{this.context.translate('КПП')}:&nbsp;</strong>
                                        {SupplierKpp.value}
                                    </div>
                                </div>

                                <div className="company-reqs__btn">
                                    <div className="u-btn u-btn_clear_blue btn_for_company"
                                         onClick={(e) => this.props.addCompany(e, 'supplierInformation')}>{this.context.translate('Изменить')}</div>
                                    <div
                                        className="u-btn u-btn_clear_blue btn_for_company"
                                        onClick={() => this.deleteCompany('supplierInformation')}
                                    >{this.context.translate('Удалить')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/*<div className="form__row">*/}
            {/*    {this.renderField('SupplierContactPersonWebsite')}*/}
            {/*    <div className="form__field"></div>*/}
            {/*</div>*/}

            {/*<h2 className="form__title">{this.context.translate('Условия финансирования')}</h2>*/}
            {/*<div className="form__row">*/}
            {/*    <div className="form__field">*/}
            {/*        {this.contentTotalPrice()}*/}
            {/*    </div>*/}
            {/*    <div className="form__subrow">*/}
            {/*        {this.renderField('AdvancePercent')}*/}
            {/*        {this.renderField('ContractTerm')}*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="form__row">*/}
            {/*    <div className="form__field">*/}
            {/*        {this.renderField('LeasePaymentDay')}*/}
            {/*    </div>*/}
            {/*    <div className="form__field"></div>*/}
            {/*</div>*/}

            {/*<h2 className="form__title">{this.context.translate('Компания-лизингополучатель')}</h2>*/}
            {/*<div className="form__row form__row_button">*/}
            {/*    {(checkLengthCompany > 0)*/}
            {/*        && (*/}
            {/*            <React.Fragment>*/}
            {/*                {this.renderField('LesseeId')}*/}
            {/*            </React.Fragment>*/}
            {/*        )}*/}

            {/*    <div className={`form__field ${checkLengthCompany > 0 ? 'jcfe' : ''}`}>*/}
            {/*        {(!(LesseeManualFullName.value && !fields.LesseeId && !LesseeId.value))*/}
            {/*            && (*/}
            {/*                <React.Fragment>*/}
            {/*                    {(checkLengthCompany < 1) && <div className="form__custom_label">Укажите компанию</div>}*/}
            {/*                    <a*/}
            {/*                        className={"btn btn_clear_blue btn_add form__button btn_width LesseeId"}*/}
            {/*                        href="#"*/}
            {/*                        onClick={(e) => {*/}
            {/*                            this.onChangeField('LesseeId', this.state.LesseeId.value, true);*/}
            {/*                            this.props.addCompany(e, 'lesseeCompany');*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        {this.context.translate('Добавить по ИНН')}*/}
            {/*                    </a>*/}
            {/*                </React.Fragment>*/}
            {/*            )}*/}
            {/*        {this.state.LesseeId.error && this.props.catalog && this.props.catalog.leaseCompanies && this.props.catalog.leaseCompanies.length === 0 &&*/}
            {/*            <span className="error">Это поле необходимо заполнить</span>}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*{LesseeManualFullName.value && !fields.LesseeId && !LesseeId.value*/}
            {/*    && (*/}
            {/*        <div className="application-edit__company-reqs">*/}
            {/*            <div className="company-reqs">*/}
            {/*                <div className="company-reqs__wrap">*/}
            {/*                    <div className="company-reqs__title">{LesseeManualFullName.value}</div>*/}
            {/*                    <div className="company-reqs__inner">*/}
            {/*                        <div className="company-reqs__item company-reqs__item_inline">*/}
            {/*                            <strong>{this.context.translate('ИНН')}:&nbsp;</strong>{LesseeManualTin.value}*/}
            {/*                        </div>*/}
            {/*                        <div className="company-reqs__item">*/}
            {/*                            <strong>{this.context.translate('КПП')}:&nbsp;</strong>{LesseeManualKpp.value}*/}
            {/*                        </div>*/}
            {/*                        <div className={`company-reqs__item ${isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*                            <strong>{this.context.translate('Краткое наименование')}:&nbsp;</strong>{LesseeManualShortName.value}*/}
            {/*                        </div>*/}
            {/*                        <div className="company-reqs__item">*/}
            {/*                            <strong>{this.context.translate('ОПФ')}:&nbsp;</strong>{findLabelOPF && findLabelOPF.label}*/}
            {/*                        </div>*/}
            {/*                        <div className="company-reqs__item">*/}
            {/*                            <strong>{this.context.translate(`${!isIP ? 'ОГРН' : 'ОГРНИП'}`)}:&nbsp;</strong>{LesseeManualOGRN.value}*/}
            {/*                        </div>*/}
            {/*                        <div className="company-reqs__item">*/}
            {/*                            <strong>{this.context.translate(`${!isIP ? 'Юридический адрес' : 'Место регистрации'}`)}:&nbsp;</strong>*/}
            {/*                            {LesseeManualIndexLegal.value}, {LesseeManualCityLegal.value}, {LesseeManualAddressLegal.value}*/}
            {/*                        </div>*/}
            {/*                        <div className="company-reqs__item">*/}
            {/*                            <strong>{this.context.translate(`${!isIP ? 'Фактический адрес' : 'Адрес местонахождения бизнеса'}`)}:&nbsp;</strong>*/}
            {/*                            {LesseeManualIndexActual.value}, {LesseeManualCityActual.value}, {LesseeManualAddressActual.value}*/}
            {/*                        </div>*/}
            {/*                        <div className="company-reqs__item">*/}
            {/*                            <strong>{this.context.translate('Адрес для корреспонденции')}:&nbsp;</strong>*/}
            {/*                            {LesseeManualIndexPost.value}, {LesseeManualCityPost.value}, {LesseeManualAddressPost.value}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="company-reqs__btn">*/}
            {/*                        <div className="u-btn u-btn_clear_blue btn_for_company"*/}
            {/*                             onClick={(e) => this.props.addCompany(e, 'lesseeCompany')}>{this.context.translate('Изменить')}</div>*/}
            {/*                        <div*/}
            {/*                            className="u-btn u-btn_clear_blue btn_for_company"*/}
            {/*                            onClick={() => this.deleteCompany('lesseeCompany')}*/}
            {/*                        >{this.context.translate('Удалить')}</div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}

            {/*<div className="form__row">*/}
            {/*    <div className={`form__field ${isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*        {this.renderField('LesseeLeader')}*/}
            {/*    </div>*/}
            {/*    {this.renderField('LesseeEmail')}*/}
            {/*</div>*/}

            {/*<div className="form__row">*/}
            {/*    {this.renderField('LesseePhone')}*/}
            {/*    {this.renderField('LesseeWebsite')}*/}
            {/*</div>*/}

            {/*<div className={`form__row ${isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*    {this.renderField('LesseeRegDate')}*/}
            {/*    <div className="form__field"></div>*/}
            {/*</div>*/}

            {/*<div className={`form__row ${!isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*    {this.renderField('LesseeBirthdate')}*/}
            {/*    {this.renderField('LesseeSnils')}*/}
            {/*</div>*/}

            {/*<div className={`form__row ${!isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*    {this.renderField('LesseeBirthplace')}*/}
            {/*</div>*/}

            {/*<div className={`form__row ${!isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*    <div className="form__subrow">*/}
            {/*        {this.renderField('LesseePassportNumber')}*/}
            {/*        {this.renderField('LesseePassportDate')}*/}
            {/*    </div>*/}
            {/*    <div className="form__field">*/}
            {/*        {this.renderField('LesseePassportDivisionCode')}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className={`form__row ${!isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*    {this.renderField('LesseePassportIssue')}*/}
            {/*</div>*/}

            {/*<div className={`form__row ${(isIP || !hideFeilds) ? 'hide' : ''}`}>*/}
            {/*    {this.renderField('LesseeReorganizedFullName')}*/}
            {/*    {this.renderField('LesseeReorganizedShortName')}*/}
            {/*</div>*/}
            {/*<div className={`form__row ${(isIP || !hideFeilds) ? 'hide' : ''}`}>*/}
            {/*    {this.renderField('LesseeReorganizedOGRN')}*/}
            {/*    <div className="form__field"></div>*/}
            {/*</div>*/}
            {/*<div className={`form__row ${isIP ? 'hide_if_not_ip' : ''}`}>*/}
            {/*    {this.renderField('IsLesseeReorganized')}*/}
            {/*</div>*/}

            <h2 className="form__title">{this.context.translate('Комментарий к заявке')}</h2>
            <div className="form__row">
                {this.renderField('Comment')}
            </div>

            {
                fields && fields.Documents && fields.Documents.length > 0 &&
                <OrdersDocs
                    docs={fields && fields.Documents}
                    fileBuffer={fileBuffer}
                    fileParams={fileParams}
                    clearBuffer={clearBuffer}
                    addDocument={this.addDocument}
                    updateDocument={this.updateDocument}
                    removeDocument={this.removeDocument}
                    cancelDocument={cancelApplicationDocument}
                    download={downloadApplicationFile}
                    documentTypes={documentTypes}
                    typeName="kp"
                    onlyOneTypeName={true}
                    withoutBtn={true}
                />
            }

            {this.props.modalOpenedKp
                && (
                    <DocumentLoader
                        files={fileBuffer}
                        {...fileParams}
                        addDocument={this.addDocument}
                        removeDocument={this.removeDocument}
                        updateDocument={this.updateDocument}
                        cancelDocument={cancelApplicationDocument}
                        close={this.props.closeModalKp}
                        documentTypes={documentTypes}
                        typeName="kp"
                        onlyOneTypeName={true}
                    />
                )}

            {/*<div className="new_application__wrap">*/}
                {/*<h5 className="new_application__wrap_title">*/}
                {/*    Для рассмотрения заявки нам потребуются следующие документы:*/}
                {/*</h5>*/}
                {/*<div className="new_application__wrap_items">*/}
                {/*    {arrItems.map((item, index) =>*/}
                {/*        <div className="new_application__item" key={index}>*/}
                {/*            <div className="new_application__item_line"></div>*/}
                {/*            <div className="new_application__item_title">{item.title}</div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*<h5 className="new_application__wrap_title second">*/}
                {/*    Вы можете прикрепить их сейчас для ускорения процесса принятия решения.*/}
                {/*</h5>*/}

                {/*<div className="new_application__wrap_checkbox">*/}
                {/*    {this.renderField('IsIagree')}*/}
                {/*</div>*/}
                {/*<div className="new_application__wrap_checkbox">*/}
                {/*    {this.renderField('IsIagree2')}*/}
                {/*</div>*/}
                {/*<div className="new_application__wrap_checkbox">*/}
                {/*    {this.renderField('IsIagree3')}*/}
                {/*</div>*/}
                {/*<div className="new_application__wrap_checkbox">*/}
                {/*    {this.renderField('IsIagree4')}*/}
                {/*</div>*/}
                {/*<div className="new_application__wrap_checkbox">*/}
                {/*    <div className="form__row">*/}
                {/*        {this.renderField('IsMailing')}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className="new_application__btn_more"*/}
                {/*    onClick={toggleAgreementModal}*/}
                {/*>*/}
                {/*    Подробнее*/}
                {/*</div>*/}
            {/*</div>*/}
            
            {(!fields || fields.IsDraft)
                ? <div className="form__buttons">
                    {/*<button*/}
                    {/*    disabled={!isBlockBtn}*/}
                    {/*    className={`btn ${!isBlockBtn ? 'disabled' : ''}`}*/}
                    {/*    type="button"*/}
                    {/*    onClick={() => {*/}
                    {/*        this.props.startApplicationDiadocAction('newApplication', fields && fields.ApplicationId);*/}
                    {/*        this.onSubmit(true)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {this.context.translate('Подписать по эдо и отправить')}*/}
                    {/*</button>*/}
                    <button
                        disabled={this.props.fields && !this.props.fields.ApplicationId}
                        className={`btn ${(this.props.fields && !this.props.fields.ApplicationId) ? 'disabled' : ''}`}
                        type="button"
                        onClick={() => {this.onSubmit(false)}}
                    >
                        {this.context.translate('Отправить')}
                    </button>
                </div>
                : (IsRecreate
                    ? <div className="form__buttons">
                        {/* СКОРЕЕ ВСЕГО КНОПКИ ЭТИ УЖЕ НЕ ИСПОЛЬЗУЮТСЯ ЛИБО НЕ ИЗВЕСТНЫЙ СЦЕНАРИЙ */}
                        <button className="btn" type="button" onClick={() => {this.onSubmit(false)}}>{this.context.translate('Отредактировать и отправить заново')}</button>
                        <Link className="btn btn_clear_blue" to={"/applications" + "#archive"} onClick={this.clearDocs}>{this.context.translate('Отмена')}</Link>
                    </div>
                    : <div className="form__buttons">
                        <button className={"btn" + (this.hasChanges() ? "" : " disabled")} type="button" onClick={() => {this.onSubmit(false)}}>{this.context.translate('Сохранить')}</button>
                        <Link className="btn btn_clear_blue" to={"/applications" + "#progress"} onClick={this.clearDocs}>{this.context.translate('Отмена')}</Link>
                    </div>
                )}

            {modalAddedDocOpened
                && (
                    <Modal modalSize="s" classNameContent="modal modal_visible" onClickBackground={closeModalAddedDoc}>
                        <DocumentsAdded close={closeModalAddedDoc}/>
                    </Modal>
                )}
        </Form>
    }
}
import React,{Component} from "react";
import OrdersListItem from "./OrdersListItem";
import {Redirect} from "react-router-dom";

export default class OrdersListProgress extends Component{

    render(){

        let {
            list,
            recallApplication,
            requestApplication,
            isActionOk,
            actionMessage,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,
            documentTypes,
            pdfApplicationFormAction,
            fileSuccess,
            modalBtnIsDisabled,
            startApplicationDiadocAction,
            writeOrderFromList,
            openModalDiadocFromAdminError
        } = this.props;

        // if (!list.length) return <Redirect to={"/applications"}/>;

        return (<div className="tabs__main">
                <div className="tabs__content">
                    <div className="applications">
                        {list.map((item, key) => <OrdersListItem item={item}
                                                                 key={key}
                                                                 recallApplication={recallApplication}
                                                                 requestApplication={requestApplication}
                                                                 isActionOk={isActionOk}
                                                                 modalBtnIsDisabled={modalBtnIsDisabled}
                                                                 actionMessage={actionMessage}

                                                                 fileBuffer={fileBuffer}
                                                                 fileParams={fileParams}

                                                                 addApplicationDocument={addApplicationDocument}
                                                                 removeApplicationDocument={removeApplicationDocument}
                                                                 updateApplicationDocument={updateApplicationDocument}
                                                                 cancelApplicationDocument={cancelApplicationDocument}
                                                                 clearBuffer={clearBuffer}
                                                                 documentTypes={documentTypes}
                                                                 pdfApplicationFormAction={pdfApplicationFormAction}
                                                                 fileSuccess={fileSuccess}
                                                                 className="application_in_progress applications__item"
                                                                 startApplicationDiadocAction={startApplicationDiadocAction}
                                                                 writeOrderFromList={writeOrderFromList}
                                                                 openModalDiadocFromAdminError={openModalDiadocFromAdminError}
                        />)}
                    </div>
                </div>
            </div>
        )
    }

}
import React, {Component} from 'react';
import ApplicationsWidget from "./ApplicationsWidget";
import PromotionsWidget from "./PromotionsWidget";
import DealsWidget from "./DealsWidget";
import Preloader from "../../components/common/Preloader";
import LibraryWidget from "./LibraryWidget";
import DocumentsOrderingWidget, {DocumentOrderingOk} from "../documents/DocumentsOrderingWidget";
import Modal from "../common/Modal";
import PropTypes from "prop-types";


export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    showInvoice = () => {
        this.props.history.push({pathname: "/documents", state: {DocumentName: "Счет-фактура"}})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.documentsRequestResult != this.props.documentsRequestResult && nextProps.documentsRequestResult) {
            this.setState({modal: true});
        }
        if (nextContext.currentLanguage.alias !== this.context.currentLanguage.alias) {
            this.props.translatePromotions();
        }
    }

    render() {
        let {
            isOk,
            investPotential,
            applications,
            promotions,
            deals,
            downloadInvoice,
            library,
            downloadLibItem,
            hasPenalties,
            catalog,
            user,
            getCatalog,
            downloadHelpfulDoc,
            addDocumentRequest,
            documentsRequestResult,
            downloadReconciliationStatement,
            loading,
            documentsFormError,
            paymentNotification,
            documentStatusCount,
        } = this.props;

        return <div className="page page_projects page_main">
            <div className="page__main">
                <div className="page__main-page">
                    <div className="main-page main-page_adapt-default">
                        <div className="main-page__body">
                            <div className="main-page__col">
                                {deals.overdue.isOk && deals.overdue.pagination.totalCount > 0 ?
                                    <DealsWidget className="main-page__section_penalties"
                                                 title={this.context.translate("Штрафы")}
                                                 items={deals.overdue.slice(0, 2)}
                                                 count={deals.overdue.pagination.totalCount}
                                                 link="/deals/overdue"
                                                 downloadInvoice={downloadInvoice}
                                                 translate={this.context.translate}
                                    /> : (hasPenalties ? null :
                                        <ApplicationsWidget items={applications} banner={investPotential}
                                                            widgetCustom={catalog && catalog.info_widget}/>)}
                                {
                                    user && !!user.is_full_access &&
                                    <div className="main-page__section main-page__section_order">
                                        <DocumentsOrderingWidget catalog={catalog}
                                                                 getCatalog={getCatalog}
                                                                 downloadHelpfulDoc={downloadHelpfulDoc}
                                                                 showInvoice={this.showInvoice}
                                                                 addDocumentRequest={addDocumentRequest}
                                                                 downloadReconciliationStatement={downloadReconciliationStatement}
                                                                 loading={loading}
                                                                 documentsFormError={documentsFormError}
                                                                 documentsRequestResult={documentsRequestResult}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="main-page__col">
                                {deals.contracts.isOk && deals.contracts.pagination.totalCount > 0 ?
                                    <DealsWidget className="main-page__section_offers"
                                                 title={this.context.translate("Одобренные договоры лизинга")}
                                                 items={deals.contracts.slice(0, 2)}
                                                 count={deals.contracts.pagination.totalCount}
                                                 link="/deals/contracts"
                                                 downloadInvoice={downloadInvoice}
                                                 translate={this.context.translate}
                                    /> : null}
                                {deals.payments.isOk && deals.payments.pagination.totalCount > 0 ?
                                    <DealsWidget className="main-page__section_payments"
                                                 title={this.context.translate("Приближающиеся платежи")}
                                                 items={deals.payments.slice(0, 2)}
                                                 count={deals.payments.pagination.totalCount}
                                                 link="/deals/payments"
                                                 downloadInvoice={downloadInvoice}
                                                 paymentNotification={paymentNotification}
                                                 translate={this.context.translate}
                                    /> : null}
                                {deals.consideration.isOk && deals.consideration.pagination.totalCount > 0 ?
                                    <DealsWidget className="main-page__section_payments"
                                                 title={this.context.translate("Заявки на рассмотрении")}
                                                 items={deals.consideration.slice(0, 2)}
                                                 count={deals.consideration.pagination.totalCount}
                                                 link="/deals/consideration"
                                                 downloadInvoice={downloadInvoice}
                                                 paymentNotification={paymentNotification}
                                                 translate={this.context.translate}
                                                 typeDeals="consideration"
                                    /> : null}
                                {library && library.pagination.totalCount > 0 ?
                                    <LibraryWidget items={library.slice(0, 2)}
                                                   count={library.pagination.totalCount}
                                                   download={downloadLibItem}
                                    /> : null}
                            </div>
                        </div>
                        <PromotionsWidget items={promotions}/>
                    </div>
                    {isOk ? null : <Preloader/>}
                </div>
                {this.state.modal ?
                    <Modal modalSize="s" classNameContent="modal_fullscreen page__widget-modal" onClickBackground={() => {
                        this.setState({modal: false})
                    }}>
                        <DocumentOrderingOk {...documentsRequestResult} onClick={() => {
                            this.setState({modal: false})
                        }} translate={this.context.translate}/>
                    </Modal> : null}
            </div>
        </div>
    }
}
import React, {Component} from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import Collapser from "../common/Collapser";
import PropTypes from "prop-types";

export default class CommunicationsSearchForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            TextOrNumber: {
                type: 'text',
                label: '',
                placeholder: "Текст или номер обращения",
                value: ""
            },
            date: {
                type: 'date-range',
                label: "Дата отправки",
                value: ""
            },
            CommunicationTypeId: {
                type: 'select',
                label: "Тип обращения",
                placeholder: "Выберите тип",
                value: "",
                options: props.catalog && props.catalog.communicationTypes || [],
                optionsType: 'communicationTypes',
                allowEmpty: true,
                hidden: props.catalog && props.catalog.communicationTypes.length <= 1
            },
            CommunicationStatusId: {
                type: 'select',
                label: "Статус обращения",
                placeholder: "Выберите статус",
                value: "",
                options: props.catalog && props.catalog.communicationStatuses || [],
                optionsType: 'communicationStatuses',
                allowEmpty: true,
                allowSearch: true,
                hidden: props.catalog && props.catalog.communicationStatuses.length <= 1
            }
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    addCatalogOptions = (state, catalog) => {
        for (var key in state) {
            if (state[key].hasOwnProperty('optionsType') && catalog[state[key].optionsType] && state[key].options != catalog[state[key].optionsType]) {
                state[key].options = catalog[state[key].optionsType];
                state[key].hidden = catalog[state[key].optionsType].length <= 1;
            }

        }
        return state;
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.catalog != this.props.catalog) {
            var state = {...this.state};
            state = this.addCatalogOptions(state, nextProps.catalog);
            this.setState(state);
        }
    }

    renderField = (attribute) => {
        var fields = {...this.state};

        return fields.hasOwnProperty(attribute) && !fields[attribute].hidden ?
            <FormField fieldConfig={{
                ...fields[attribute],
                label: this.context.translate(fields[attribute].label),
                placeholder: this.context.translate(fields[attribute].placeholder)
            }} attribute={attribute} onChange={this.onChange}/> : null;
    }

    onChange = (attribute, value) => {
        var fields = {...this.state};
        fields[attribute].value = value;
        this.setState(fields, () => {
            if (attribute == 'TextOrNumber') {
                this.onSubmit();
            }
        });
    }

    onSubmit = () => {
        var fields = {...this.state};
        if (fields.date.value) {
            if (fields.date.value.from) fields.SendDateStart = {value: (fields.date.value.from).split('.').reverse().join('-')};
            if (fields.date.value.to) fields.SendDateEnd = {value: (fields.date.value.to).split('.').reverse().join('-')};
        }
        delete fields['date'];
        this.props.search(fields);
    }

    onReset = () => {
        var fields = {...this.state};
        for (var attr in fields) {
            fields[attr].value  = "";
            this.setState(fields);
        }
        this.props.search(fields);
    }

    render(){
        return <Form className="messages-filter__form" onSubmit={this.onSubmit}>
            <div className="messages-filter__header">
                <div className="messages-filter__search">
                    {this.renderField('TextOrNumber')}
                </div>
            </div>
            <Collapser label={this.context.translate("Расширенный поиск")}
                       labelClassName="messages-filter__open js-collapser u-more-link u-more-link-communication"
                       labelActiveClassName="u-more-link_open"
                       contentClassName="u-collapser messages-filter__body"
                       contentActiveClassName="u-collapser_open"
            >
                <div className="u-collapser__container messages-filter__body-inner">
                    <div className="messages-filter__date">
                        {this.renderField('date')}
                    </div>
                    <div className="messages-filter__type">
                        {this.renderField('CommunicationTypeId')}
                    </div>
                    <div className="messages-filter__state">
                        {this.renderField('CommunicationStatusId')}
                    </div>
                </div>
            </Collapser>
            <div className="messages-filter__footer">
                <div className="messages-filter__actions">
                    <div className="messages-filter__action messages-filter__action_submit">
                        <button className="u-btn messages-filter__action-button messages-filter__action-button_submit" type="submit">
                            {this.context.translate('Найти')}
                        </button>
                    </div>
                    <div className="messages-filter__action messages-filter__action_reset">
                        <button className="u-reset-link messages-filter__action-button messages-filter__action-button_reset" type="reset" onClick={this.onReset}>
                            <span>{this.context.translate('Сбросить')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    }
}
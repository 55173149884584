import React,{Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import {OrderStatus, OrderActions} from "./OrderHelpers";
import DocumentLoader from "../common/DocumentLoader";
import {CircleMore} from "../deals/DealItemRound";
import Modal from "../common/Modal";
import ApplicationRemove from "../companies/ApplicationRemove";
import closeGray from "../../../img/icons/close_gray.svg";
import DocumentsAdded from "../companies/DocumentsAdded";


export default class OrdersListItem extends Component{

    constructor(props){
        super(props);

        var item = props.item;

        var linkTo = "";
        if (item.Actions.indexOf("resend") != -1) {
            linkTo = {
                pathname: "/applications/create",
                state: {from: "recreate", id: item.ApplicationId}
            }
        } else if (item.IsApplicationEditable) {
            linkTo = "/applications/update/"+item.ApplicationId
        } else linkTo = {
            pathname: "/applications/view/"+item.ApplicationId,
            state: {from: location.pathname}
        };

        this.state = {
            linkTo,
            modalOpened: false,
            modalRemove: false,
            modalAddedDocOpened: false,
            itemId: '',
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        var item = nextProps.item;

        if (item.Actions != this.props.item.Actions) {
            var linkTo = "";
            if (item.Actions.indexOf("resend") != -1) {
                linkTo = {
                    pathname: "/applications/create",
                    state: {from: "recreate", id: item.ApplicationId}
                }
            } else if (item.IsApplicationEditable) {
                linkTo = "/applications/update/"+item.ApplicationId
            } else linkTo = {
                pathname: "/applications/view/"+item.ApplicationId,
                state: {from: location.pathname}
            };

            this.setState({linkTo});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { fileSuccess } = this.props;

        if ((prevProps.fileSuccess !== fileSuccess && fileSuccess) && this.state.itemId) {
            // открывает содалку после загрузки документов "Документы добавлены"
            this.openModalAddedDoc();
            this.setState({itemId: ''});
        }
    }

    toggleModal = () => {
        this.props.clearBuffer();
        this.setState((prevState) => {
            return {
                modalOpened: !prevState.modalOpened
            }
        })
    }

    // для модального окна "Удаление черновика" по клику на крестик
    toggleRemoveModal = () => {
        this.setState((prevState) => {
            return {
                modalRemove: !prevState.modalRemove
            }
        })
    }

    // открывает содалку "Документы добавлены"
    openModalAddedDoc = () => {
        this.setState(() => {
            return {
                modalAddedDocOpened: true
            }
        })
    };

    // закрывает содалку "Документы добавлены"
    closeModalAddedDoc = () => {
        this.setState(() => {
            return {
                modalAddedDocOpened: false
            }
        })
    };

    addDocument = (file) => {
        this.props.addApplicationDocument(this.props.item.ApplicationId, file);
        this.setState({itemId: this.props.item.ApplicationId});
    }

    removeDocument = (id) => {
        this.props.removeApplicationDocument(this.props.item.ApplicationId, id);
    }

    updateDocument = (id, name) => {
        this.props.updateApplicationDocument(this.props.item.ApplicationId, id, name)
    }

    renderCircle = (item) => {
        return <CircleMore DaysMore={item.DaysLeft}
                    DaysLimit={90}
                    style={item.DaysLeft > 60 ? 'reminder' : (item.DaysLeft > 30 ? 'warning' : (item.DaysLeft > 5 ? 'alert' : 'danger'))}
                    full={false}
                    translate={this.context.translate}
                    leftWord={<span className={'text_left'}>Срок одобрения</span>}
        />
    }


    render(){
        let {
            className,
            item,
            sendApplication,
            recallApplication,
            requestApplication,
            resumeApplication,
            isActionOk,
            actionMessage,
            documentTypes,
            typeApplication,

            fileBuffer,
            fileParams,
            cancelApplicationDocument,
            pdfApplicationFormAction,
            modalBtnIsDisabled,
            startApplicationDiadocAction,
            writeOrderFromList,
            openModalDiadocFromAdminError,

            IsHome = false //is for home widget

        } = this.props;

        return <div className={className ? className : "applications__item"}>
            <div>
                <div className="applications__wrap_item-title">
                    <div className="applications__item-title">
                        {IsHome ? (item.Status ? this.context.translate("Заявка") : this.context.translate("Неотправленная заявка"))+" " : ""}
                        <Link to={this.state.linkTo}>{item.LinkTitle}</Link>&nbsp;
                        {item.AdditionalTitle ? <span>{item.AdditionalTitle}</span> : null}
                    </div>
                    
                    {typeApplication === 'remove'
                        && (
                            <div className="applications__wrap_icon_close">
                                <img
                                    onClick={this.toggleRemoveModal}
                                    src={closeGray} 
                                    alt="Удалить"
                                />
                            </div>
                        )}
                    
                </div>
                {item.Status ? <OrderStatus status={item.Status} paused={item.FrozenTo} /> : null}
                {/*{!IsHome && item.DaysLeft ? <div className={'application_circle__xs'}>*/}
                {/*    {this.renderCircle(item)}*/}
                {/*</div> : null}*/}
                {item.Actions ? <OrderActions actions={item.Actions}
                                              id={item.ApplicationId}
                                              projectId={item.ProjectId}

                                              sendApplication={sendApplication}
                                              recallApplication={recallApplication}
                                              requestApplication={requestApplication}
                                              resumeApplication={resumeApplication}
                                              uploadDocuments={this.toggleModal}
                                              pdfApplicationFormAction={pdfApplicationFormAction}

                                              isActionOk={isActionOk}
                                              modalBtnIsDisabled={modalBtnIsDisabled}
                                              actionMessage={actionMessage}
                                              startApplicationDiadocAction={startApplicationDiadocAction}
                                              writeOrderFromList={() => {writeOrderFromList(item)}}
                                              openModalDiadocFromAdminError={openModalDiadocFromAdminError}
                />: null}

                {this.state.modalOpened ? <DocumentLoader files={fileBuffer}
                                                          {...fileParams}
                                                          addDocument={this.addDocument}
                                                          removeDocument={this.removeDocument}
                                                          updateDocument={this.updateDocument}
                                                          cancelDocument={cancelApplicationDocument}
                                                          close={this.toggleModal}
                                                          documentTypes={documentTypes}
                />: null}
            </div>
            {/*{!IsHome && item.DaysLeft ? <div className={'application_circle__lg'}>*/}
            {/*    {this.renderCircle(item)}*/}
            {/*</div> : null}*/}
            <div className="u-svg-declare">
                <svg width="0" height="0">
                    <defs>
                        <linearGradient id="gradient_green">
                            <stop offset="0%" style={{stopColor: "#28710b"}}/>
                            <stop offset="100%" style={{stopColor: "#aab414"}}/>
                        </linearGradient>
                        <linearGradient id="gradient_yellow">
                            <stop offset="0%" style={{stopColor: "#ffa200"}}/>
                            <stop offset="100%" style={{stopColor: "#fbd254"}}/>
                        </linearGradient>
                        <linearGradient id="gradient_red">
                            <stop offset="0%" style={{stopColor: "#fa2719"}}/>
                            <stop offset="100%" style={{stopColor: "#ff3c06"}}/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            {this.state.modalRemove
                && (
                    <Modal modalSize="s" classNameContent="modal modal_visible" onClickBackground={this.toggleRemoveModal}>
                        <ApplicationRemove id={item && item.ApplicationId} toggleRemoveModal={this.toggleRemoveModal}/>
                    </Modal>
                )}
            
            {this.state.modalAddedDocOpened
                && (
                    <Modal modalSize="s" classNameContent="modal modal_visible" onClickBackground={this.closeModalAddedDoc}>
                        <DocumentsAdded close={this.closeModalAddedDoc} />
                    </Modal>
                )}
        </div>
    }
}
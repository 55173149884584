import React, {Component} from "react";
import {getWord} from "../../../tools/tools";
import PropTypes from "prop-types";

export default class CheckBox extends Component{

    constructor(props, context) {
        super(props, context);
    }
    onChange = () => {
        let {
            attribute,
            value,
            onChange,
            saveField,
        } = this.props;

        onChange && onChange(attribute, !value);
        saveField && saveField(attribute, !value);
    }
    static contextTypes = {
        translate: PropTypes.func
    };

    render(){

        let {
            id,
            value,
            label,
            className,
            labelOptions = {}
        } = this.props;

        return (
            <div
                className={className ? className : "form__checkbox"}
                onClick={(e) => { e.stopPropagation(); e.nativeEvent.stopImmediatePropagation() }}
            >
                <input 
                    id={id}
                    type="checkbox"
                    checked={value}
                    onChange={this.onChange}
                />
                <label
                    htmlFor={id}
                >
                    <span dangerouslySetInnerHTML={{
                        __html: this.context.translate(label, labelOptions)
                    }} />
                </label>
            </div>
        )
    }
}